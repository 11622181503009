<template>
  <div class="footer">
    <div class="footer_wrapper">
      <div class="footer_nav">
        <div class="nav_item" v-for="(item, index) in navList" :key="index">
          <div class="nav_sub" @click="goNext(item)">
            <span>{{ item.meta.title }}</span>
          </div>
        </div>
      </div>
      <div class="logo" @click="$router.push('/home/page')">
        <img :src="logo" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    logo: {
      type: String,
      default: "",
    }
  },
  data() {
    return {};
  },
  methods: {
    goNext(item) {
      if (this.$route.path == item.path) return false;
      this.$router.push(item.path);
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: transparent;
  z-index: 9999;
  position: relative;
  padding: 50px 0;
}
.logo {
  height: 40px;
  cursor: pointer;
}
.footer_wrapper {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
}
.footer_nav {
  width: 80%;
}
.nav_item {
  margin-bottom: 10px;
  cursor: pointer;
  color: var(--dynamic-color);
}
</style>