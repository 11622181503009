<template>
  <div class="nav">
    
    <div class="nav_container">
      <div class="logo" @click="goNext(navList[0], 0)">
        <img :src="logo" alt="" />
      </div>
      <div
        class="nav_item"
        v-for="(item, index) in navList"
        :key="index"
        ref="navItem"
      >
        <span
          :class="[clickNavIndex === index ? 'navActive' : '']"
          @click.prevent="goNext(item, index)"
          >{{ item.meta.title }}</span
        >
      </div>
    </div>
    <div class="right_container">
      <div class="search_container">
        <div class="search_init" @click="openDrawer">
          <i class="el-icon-search"></i>
        </div>
      </div>
    </div>
    <div class="drawer_bg" v-show="drawer" @click="closeDrawer"></div>
    <div class="drawer" v-show="drawer" ref="drawer">
      <div class="close" @click.prevent="closeDrawer">
        <span class="el-icon-close"></span>
      </div>
      <div class="drawer_wrapper">
        <div class="drawer_container">
          <div class="category">
            <div
              class="category_item"
              v-for="(item, index) in cateArr"
              :key="index"
              :class="{ cateActive: index === cateIndex }"
              @click.prevent="changeCate(index)"
            >
              <span>{{ item }}</span>
              <span class="line"></span>
            </div>
          </div>
          <div class="search_wrapper">
            <span @click.prevent="search" class="el-icon-search"></span>
            <input
              v-model.trim="text"
              type="text"
              placeholder="请输入关键词搜索"
              clearable
              @keyup.enter="search"
            />
            <span
              @click.prevent="text = ''"
              class="icon iconfont icon-cha"
            ></span>
          </div>
          <div class="history_container">
            <div class="history_title">
              <span>历史记录</span>
              <span @click="clearHistory" class="el-icon-delete"></span>
            </div>
            <div class="history_list">
              <div
                class="history_item"
                v-for="(item, index) in historyArr"
                :key="index"
                @click="text = item"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "../bus/bus";
export default {
  props: {
    navList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    logo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      idx: null,
      drawer: false,
      navIndex: null,
      clickNavIndex: null,
      cateIndex: 0,
      cateArr: ["Product"],
      text: "",
      historyArr: localStorage.historyArr
        ? JSON.parse(localStorage.historyArr)
        : [],
    };
  },
  methods: {
    goNext(item, index) {
      this.navIndex = index;
      this.clickNavIndex = index;
      sessionStorage.setItem("clickNavIndex", index);
      if (this.$route.path == item.path) return false;
      this.$router.push(item.path);
    },
    goNextTwo(item) {
      if (item.name === "marketlist") {
        if (this.$route.path === item.path) return false;
        this.$router.push(item.path);
      } else {
        if (this.$route.path === item.link) return false;
        this.$router.push(item.link);
      }
    },
    goNextThree(item) {
      let link = `/product/detail/${item[0]._id}`;
      if (this.$route.path === link) return false;
      this.$router.push(link);
    },
    search() {
      if (this.text) {
        let index = this.historyArr.findIndex((item) => item === this.text);
        if (index < 0) {
          this.historyArr.unshift(this.text);
          localStorage.historyArr = JSON.stringify(this.historyArr);
        }
        if (this.$route.query.text !== this.text) {
          this.$router.push({
            path: "/search",
            query: {
              text: this.text,
            },
          });
        }

        setTimeout(() => {
          this.closeDrawer();
        }, 1000)
      }
    },
    clearHistory() {
      this.historyArr = [];
      localStorage.historyArr = JSON.stringify(this.historyArr);
    },
    changeCate(index) {
      this.cateIndex = index;
    },
    openDrawer() {
      this.drawer = true;
      setTimeout(() => {
        this.$refs.drawer.style.transform = "translateY(0)";
      }, 100);
    },
    closeDrawer() {
      this.$refs.drawer.style.transform = "translateY(-100%)";
      this.text = "";
      setTimeout(() => {
        this.drawer = false;
      }, 400);
    },
  },
  created() {
    bus.$on("changePage", (data) => {
      let path;
      if (data.name === "应用市场") {
        path = this.navList[1].children[data.index].path;
      } else if (data.name === "产品中心") {
        path = this.navList[2].link;
      }
      this.$router.push(path);
    });
    bus.$on("emitSearch", () => {
      this.openDrawer();
    });
    this.clickNavIndex =
      sessionStorage.getItem("clickNavIndex") === "null"
        ? null
        : ~~sessionStorage.getItem("clickNavIndex");
  },
  beforeDestroy() {
    bus.$off("changePage");
  },
};
</script>

<style scoped>
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 90px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  background-color: var(--dynamic-backgroundColor);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 4px 0 rgba(0, 0, 0, 0.04);
}

.logo {
  height: 40px;
  cursor: pointer;
}

.nav_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}

.nav_item {
  cursor: pointer;
  height: 100%;
  position: relative;
  margin: 0 20px;
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: bold;
  color: var(--dynamic-color);
}

.nav_item > span {
  display: block;
  line-height: 90px;
  transition: all 0.3s;
}

.nav_item > span:hover {
  color: #d18c83;
}

.nav_item > span.navActive {
  color: #d18c83;
}

.right_container {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 20px;
}

.search_container {
  color: var(--dynamic-color);
  font-size: 20px;
}

.drawer_bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2000;
}

.drawer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  /* height: 100%; */
  z-index: 2001;
  transform: translateY(-100%);
  transition: transform 0.4s;
}

.close {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
  cursor: pointer;
}

.close span {
  font-size: var(--first-1-size);
  color: var(--dynamic-color);
}

.drawer_wrapper {
  background-color: #fff;
  padding: 50px 0;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.drawer_container {
  max-width: 1200px;
  margin: 0 auto;
}

.category {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.category_item {
  margin-right: 30px;
  color: #525252;
  cursor: pointer;
}

.category_item.cateActive {
  color: #000;
}

.category_item .line {
  width: 0;
  height: 3px;
  background-color: var(--dynamic-color);
  display: block;
  margin-top: 5px;
  opacity: 0;
}

.category_item.cateActive .line {
  opacity: 1;
  width: 100%;
}

.category_item:hover .line {
  opacity: 1;
  width: 100%;
  transition: width 0.4s;
}

.search_wrapper {
  margin-bottom: 30px;
  border-bottom: 1px solid var(--dynamic-color);
  padding-bottom: 10px;
  position: relative;
  display: flex;
  align-items: center;
}

.search_wrapper span {
  font-size: var(--first-1-size);
  color: var(--dynamic-color);
  cursor: pointer;
}

.search_wrapper input {
  border: none;
  padding-left: 30px;
  width: 100%;
}

.history_title {
  color: #8f8f8f;
  margin-bottom: 30px;
}

.history_title span.el-icon-delete {
  margin-left: 5px;
  cursor: pointer;
}

.history_list {
  display: flex;
  flex-wrap: wrap;
}

.history_item {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 30px;
  padding: 5px 20px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>