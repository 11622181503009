import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload';
import './plugin/element';
import './common/root.css';

document.title = "juicy-ice";

Vue.config.productionTip = false;

Vue.use(VueLazyload, {
  error: require('./assets/error.jpg'),
  loading: require('./assets/loading.gif'),
  attempt: 1,
  preLoad: 1.3
});


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
