import axios from "axios";

const baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:4130/web/api/' : 'http://43.134.240.141:4130/web/api/';
const request = axios.create({
    baseURL,
    timeout: 50000
});

// let language = 'zh';
// const host = window.location.host.split('.')[0];
// if(host === 'zhjy') {
//     language = 'zh';
// } else if (host === 'enjy') {
//     language = 'en';
// } else {
//     language = 'jp';
// }

request.interceptors.request.use(config => {
    let language = 'zh';
    config.headers.Language = language;
    return config;
});

request.interceptors.response.use(res => {
    return res.data
}, err => {
    return '服务器响应数据失败'
})

export default request;