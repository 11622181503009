<template>
  <div>
    <header-nav ref="headerNav" :navList="navList" :logo="logo"></header-nav>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    <footer-nav :navList="navList" :logo="logo"></footer-nav>
  </div>
</template>

<script>
import HeaderNav from "../components/HeaderNav.vue";
import FooterNav from "../components/FooterNav.vue";
import { navListFunction, getSettingFunction } from "../api/api";
export default {
  components: {
    HeaderNav,
    FooterNav
  },
  data() {
    return {
      logo: "",
      navList: [],
    };
  },
  methods: {
    async fetchNav() {
      const res = await navListFunction();
      if (res.code !== 200) return false;
      this.navList = res.data;
    },
    async getSetting(){
      const res = await getSettingFunction();
      this.logo = res.data.logo[0];
      this.color = res.data.color;
      document.documentElement.style.setProperty('--dynamic-backgroundColor', res.data.backgroundColor);
      document.documentElement.style.setProperty('--dynamic-color', this.color);
      let link = document.querySelector("link[rel*='icon']");
      link.href = res.data.logo[0];
    }
  },
  created() {
    this.fetchNav();
    this.getSetting();
  },
  watch: {
    $route() {
      document.documentElement.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-active {
  transition: all 0.5s;
}

.fade-leave-active {
  transition: all 0.5s;
}
</style>